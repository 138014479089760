<template>
  <div class="section">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-input label="商户名称" clearable v-model="searchParam.title" />
        <v-input
          label="注册号/统一社会信用代码"
          clearable
          v-model="searchParam.taxpayerRegistrationNumber"
        />
        <v-input
          label="个体户经营者/法人姓名"
          clearable
          v-model="searchParam.legalPersonName"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看电话" type="text" @click="getDecPhone(scope.row)" />
        <v-button text="详情" type="text" @click="toView(scope.row)" />
        <v-button
          text="审核"
          type="text"
          v-if="scope.row.auditStatus == 0"
          @click="toAudio(scope.row)"
        />
      </template>
    </v-list>
    <v-dialog
      title="联系电话"
      v-model="dialogFormVisible"
      width="50%"
      @confirm="doCancel"
    >
      <p>{{ tel }}</p>
    </v-dialog>
  </div>
</template>
<script>
import { getGroupLeaderListURL, getDecPhoneUrl } from "./api.js";
import {
  auditStatusMap,
  setAuditStatusOps,
  principalTypeMap,
  setPrincipalTypeOps,
} from "./map.js";
export default {
  data() {
    return {
      auditStatusOps: setAuditStatusOps(),
      principalTypeOps: setPrincipalTypeOps(),
      dialogFormVisible: false,
      tel: "",
      searchParam: {
        title: null,
        taxpayerRegistrationNumber: "",
        legalPersonName: "",
      },
      communitList: [],
      tableUrl: getGroupLeaderListURL,
      headers: [
        {
          prop: "principalType",
          label: "主体类型",
          formatter(row, prop) {
            return principalTypeMap[row[prop]];
          },
        },
        {
          prop: "title",
          label: "商户名称",
        },
        {
          prop: "taxpayerRegistrationNumber",
          label: "注册号/统一社会信用代码",
        },
        {
          prop: "legalPersonName",
          label: "个体户经营者/法人姓名",
        },
        {
          prop: "idNumber",
          label: "经营者/法人身份证件",
        },
        {
          prop: "mobileNum",
          label: "联系电话",
        },
        {
          prop: "auditStatus",
          label: "审核状态",
          formatter(row, prop) {
            return auditStatusMap[row[prop]];
          },
        },
      ],
    };
  },
  methods: {
    doCancel() {
      this.dialogFormVisible = false;
    },
    // 获取商品icon图
    async getDecPhone(row) {
      let params = {
        id: row.id,
      };
      let res = await this.$axios.get(getDecPhoneUrl, { params });
      if (res.code == 200) {
        this.tel = res.data;
        this.dialogFormVisible = true;
      }
    },
    toAdd() {
      this.$router.push({
        name: "audioGroupHeaderForm",
      });
    },
    toView(data) {
      this.$router.push({
        name: "audioGroupHeaderForm",
        query: { id: data.id, isView: 1 },
      });
    },
    toAudio(data) {
      this.$router.push({
        name: "audioGroupHeaderForm",
        query: { id: data.id, isAudio: 1 },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
}
</style>
